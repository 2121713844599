import {LitElement, css, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';

import {setBasePath} from '@shoelace-style/shoelace/dist/utilities/base-path.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';

import '../components/app-banner';
import '../components/app-search';

import {fetchGet} from '../functions/utils';

setBasePath('/dist');

import {baseStyles} from '../styles/base-styles';

interface TagItem {
    id: number;
    name: string;
    icon: string;
    link: string;
}

@customElement('app-home')
export class AppHome extends LitElement {
    static styles = [
        baseStyles,
        css`
            :host {
                --border-color: var(--sl-color-neutral-300);
                --border-radius: var(--sl-border-radius-medium);
            }

            .list {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                gap: 10px;
            }

            .item {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                border: 1px solid var(--border-color);
                border-radius: var(--border-radius);
                padding: var(--sl-spacing-small);
                box-shadow: var(--sl-shadow-small);
                text-decoration: none;
                color: unset;
            }

            .item img {
                margin: 0 15px;
            }
        `
    ];

    @property({type: Array})
    items: TagItem[] = [];

    constructor() {
        super();
    }

    async connectedCallback() {
        super.connectedCallback();
        await this.loadItems();
    }

    async loadItems() {
        this.items = [];
        const result = await fetchGet('/action/tags/popular');
        if(result.data?.items){
            this.items = result.data.items;
        }
    }

    render() {
        return html`
            <div class="d-flex justify-content-center">
                <div class="inner-container" style="margin-top: 10px; margin-bottom: 10px;">
                    <app-banner></app-banner>
                    <app-search></app-search>
                    <div class="list" style="padding-top: var(--sl-spacing-medium);">
                        ${this.items.length ? this.items.map(item => this.renderItem(item)) : html`
                            <app-loading></app-loading>`}
                    </div>
                </div>
            </div>
        `;
    }

    private renderItem(item: TagItem) {
        const isWide = item.name.length > 20; // условие может варьироваться
        const itemClass = isWide ? 'item wide' : 'item';
        return html`
            <a href="${item.link}" class="${itemClass}">
                <img src="${item.icon}" width="36px" height="36px" alt="icon">
                ${item.name}
            </a>
        `;
    }
}
