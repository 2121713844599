import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import {resolveRouterPath} from '../../router';

import './phone-login'
import './vk-login'
import './ya-login'
import './sber-login'

@customElement('app-login')
export class AppLogin extends LitElement {
    static styles = css`
        .auth {
            display: grid;
            grid-template-columns: minmax(0, 320px);
            grid-template-rows: auto auto auto 1fr;
            grid-template-areas: "header" "content" "secondary" "footer";
            align-content: start;
            justify-content: center;
            text-align: center;
            min-height: 100%;
        }
        .auth__header {
            grid-area: header;
            display: grid;
            grid-gap: 20px;
            gap: 20px;
            justify-items: center;
            margin-bottom: 48px;
        }
        .auth__content {
            grid-area: content;
            display: grid;
            grid-gap: 16px;
            gap: 16px;
        }
        .auth__footer {
            font-size: 13px;
            line-height: 18px;
            align-self: end;
            grid-area: footer;
            margin-top: 28px;
            white-space: pre-line;
            display: grid;
            margin-bottom: 48px;
        }
        .auth__divider {
            overflow: hidden;
        }
        .auth__divider:before,
        .auth__divider:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            box-sizing: border-box;
            width: 100%;
            height: var(--sl-spacing-3x-small);
            margin: 0 var(--sl-spacing-large);
            background: var(--sl-color-neutral-500);
        }
        .auth__divider:before {
            margin-left: -100%;
        }
        .auth__divider:after {
            margin-right: -100%;
        }
    `;

    handleRequestClose(event: CustomEvent) {
        console.log(event.detail.source);
        if (event.detail.source === 'overlay') {
            event.preventDefault();
        }
    }

    handleHide() {
        this.dispatchEvent(new CustomEvent('login-dialog-close', {
            detail: {},
            bubbles: true,
            composed: true
        }));
    }

    render() {
        return html`
            <sl-dialog class="dialog-overview" @sl-request-close=${this.handleRequestClose} @sl-hide=${this.handleHide} open>
                <div class="auth">
                    <div class="auth__header">
                        <div><img src="/local/templates/index/img/square-128.png" width="64" height="64"></div>
                        <div style="font-weight: var(--sl-font-weight-semibold); font-size: var(--sl-font-size-x-large); line-height: var(--sl-line-height-denser);">Town Online<br> вход в приложение<br> <span style="font-size: var(--sl-font-size-medium)">${pwaName}</span></div>
                    </div>
                    <div class="auth__content">
                        <phone-login></phone-login>
                        <div class="auth__divider">или с помощью</div>
                        <sber-login></sber-login>
                        <vk-login></vk-login>
                        <ya-login></ya-login>
                    </div>
                    <div class="auth__footer">
                        <div>Выполняя вход, вы&nbsp;подтверждаете, что ознакомлены с&nbsp;<a href="${resolveRouterPath('legal')}">правовыми&nbsp;документами</a> и&nbsp;соглашаетесь с&nbsp;изложенными в&nbsp;них правилами и&nbsp;условиями.</div>
                    </div>
                </div>
            </sl-dialog>
        `;
    }
}
