import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon';

import {fetchPost} from "../../functions/utils";

@customElement('ya-login')
export class YaLogin extends LitElement {
    static styles = css`
        @media (prefers-color-scheme: light) {
            sl-icon {
                color: #fc3f1d;
            }
        }
        @media (prefers-color-scheme: dark) {
            sl-icon {
                color: #fff;
            }
        }
    `
    async handleLogin() {
        const formData = new FormData();
        formData.append('backUrl', window.location.href);
        const result = await fetchPost('/auth/yaid/link', formData);
        if (result.data) {
            window.location.assign(result.data);
        }
    }

    render() {
        return html`
            <sl-button style="width: 100%;" variant="default" pill @click=${this.handleLogin}>
                <sl-icon slot="prefix" src="/dist/assets/icons/ya.svg" style="font-size: 28px"></sl-icon>
                Яндекс&nbsp;ID
            </sl-button>`;
    }
}
