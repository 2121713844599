import {LitElement, css, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {setBasePath} from '@shoelace-style/shoelace/dist/utilities/base-path.js';
import {fetchGet} from "../functions/utils";

setBasePath('/dist');

interface Banner {
    src: string;
    width: number;
    height: number;
}

@customElement('app-banner')
export class AppBanner extends LitElement {
    static styles = css`
        div{
            margin-bottom: var(--sl-spacing-medium);
            background-color: #8E8E9A;
            border-radius: var(--sl-border-radius-medium); 
        }
        img{
            max-width: 100%; 
            height: auto;
        }
    `;

    @property({type: Object})
    banner: Banner | null = null;

    constructor() {
        super();
    }

    async connectedCallback() {
        super.connectedCallback();
        await this.loadBanner();
    }

    async loadBanner() {
        const result = await fetchGet('/action/banner');
        if (result.status === 'success') {
            this.banner = result.data;
        }
    }

    render() {
        return this.banner === null ? html`` : html`<div><img src="${this.banner.src}" width="${this.banner.width}" height="${this.banner.height}"></div>`;
    }
}