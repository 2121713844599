import {LitElement, html} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';

import IMask from 'imask';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import {fetchPost} from "../../functions/utils";
import {notify} from "../../functions/notify";

@customElement('phone-login')
export class PhoneLogin extends LitElement {
    @state() phoneNumber: string = ''; // Состояние для номера телефона
    @property() phoneMask: any;

    objectToQueryString(params: { [key: string]: string }) {
        return Object.entries(params).map(([key, value]) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`;
        }).join('&');
    }

    handlePhoneMask(event: Event) {
        const element = event.target as HTMLInputElement;
        this.phoneMask = IMask(element, {
            mask: '+{7} (000) 000-00-00'
        });
    }

    handlePhone() {
        this.phoneNumber = this.phoneMask.unmaskedValue;
    }

    async handleLogin() {
        if (this.phoneNumber.length === 11) {
            const formData = new FormData();
            formData.append('phone', this.phoneNumber);
            formData.append('backUrl', window.location.href);
            const result = await fetchPost('/auth/sberid/link', formData);
            if (result.data) {
                window.location.assign(result.data);
            }else{
                await notify(result.error, 'warning');
            }
        }
    }

    render() {
        return html`
            <sl-input @sl-focus=${this.handlePhoneMask} @sl-input=${this.handlePhone} @sl-blur=${this.handlePhone}
                      type="tel" inputmode="tel" placeholder="+7 (___) __-__-__" pill>
                <sl-button @click=${this.handleLogin} variant="text" slot="suffix">Войти</sl-button>
            </sl-input>`;
    }
}
