import {State} from '@thepassle/app-tools/state.js';

interface AppState {
    isAdmin: boolean;
    isAuth: boolean;
    userEmail: '',
}

export const appState = new State({
    isAdmin: false,
    isAuth: false,
    userEmail: '',
});

export const setIsAdmin = (isAdmin: boolean) => appState.setState((old: AppState) => ({...old, isAdmin}));
export const setIsAuth = (isAuth: boolean) => appState.setState((old: AppState) => ({...old, isAuth}));
export const setUserEmail = (userEmail: string) => appState.setState((old: AppState) => ({...old, userEmail}));