import '@shoelace-style/shoelace/dist/components/alert/alert.js';

export function notify(
    message: string,
    variant: 'primary' | 'success' | 'neutral' | 'warning' | 'danger' = 'neutral',
    icon: string = 'info-circle',
    duration: number = 3000
): Promise<void> {
    function escapeHtml(html: string): string {
        const div = document.createElement('div');
        div.textContent = html;
        return div.innerHTML;
    }

    const escapedMessage = escapeHtml(message).replace(/\n/g, '<br>');

    const alert = Object.assign(document.createElement('sl-alert'), {
        variant,
        closable: true,
        duration: duration,
        innerHTML: `<sl-icon name="${icon}" slot="icon"></sl-icon>${escapedMessage}`
    });

    document.body.append(alert);
    return alert.toast();
}

export function formatErrors(errors: {message: string, code: string, customData: any}[]): string {
    return errors.map(error => error.message).join('\n');
}