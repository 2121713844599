import {formatErrors, notify} from "./notify";
import {openAuth} from "./auth";

let sessidPromise: Promise<string> | null = null;
let cachedSessid: string = ''; // Указываем тип как string и инициализируем пустой строкой

export async function getSessid(): Promise<string> {
    if (cachedSessid !== '') { // Проверяем, что не пустая строка
        return Promise.resolve(cachedSessid); // Возвращаем, так как тип уже string
    }

    if (sessidPromise !== null) {
        return sessidPromise;
    }

    sessidPromise = fetch('/action/security/sessid', {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
        .then(response => response.json())
        .then(result => {
            cachedSessid = result.data || ''; // Присваиваем строку, даже если result.data null
            return cachedSessid;
        })
        .catch(error => {
            console.error('getSessid:', error);
            return '';
        })
        .finally(() => {
            sessidPromise = null; // Сбрасываем после завершения
        });

    return sessidPromise;
}

export function getColorScheme(): string {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark';
    }
    return 'light';
}

export async function fetchGet(url: string) {
    const response = await fetch(url, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-Bitrix-Csrf-Token': await getSessid(),
        },
        method: 'GET',
    });
    const result = await response.json();
    if (response.status === 401) {
        openAuth();
    } else {
        if (result.status === 'error') {
            await notify(formatErrors(result.errors), 'danger', 'info-circle', 8000);
        }
    }
    return result;
}

export async function fetchPost(url: string, body: FormData) {
    const response = await fetch(url, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-Bitrix-Csrf-Token': await getSessid(),
        },
        method: 'POST',
        body: body,
    });
    const result = await response.json();
    if (response.status === 401) {
        openAuth();
    } else {
        if (result.status === 'error') {
            await notify(formatErrors(result.errors), 'danger', 'info-circle', 8000);
        }
    }
    return result;
}
