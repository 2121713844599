import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/button-group/button-group';
import '@shoelace-style/shoelace/dist/components/button/button';
import '@shoelace-style/shoelace/dist/components/icon/icon';

import {fetchPost, getColorScheme} from "../../functions/utils";

@customElement('vk-login')
export class VkLogin extends LitElement {
    static styles = css`
        @media (prefers-color-scheme: light) {
            sl-icon.vk {
                color: #0077FF;
            } 
            sl-icon.ok {
                color: #f70;
            }
            sl-icon.mail {
                color: #0077ff;
            }
        }
        @media (prefers-color-scheme: dark) {
            sl-icon {
                color: #fff;
            }
        }
    `
    async handleLogin(provider: string) {
        let formData = new FormData();
        formData.append('provider', provider);
        formData.append('scheme', getColorScheme());
        formData.append('backUrl', window.location.href);

        const result = await fetchPost('/auth/vkid/link', formData);
        if (result.data) {
            window.location.assign(result.data);
        }
    }


    render() {
        return html`
        <sl-button-group style="width: 100%;">
            <sl-button style="width: 100%;" variant="default" pill @click=${() => this.handleLogin('vkid')}>
                <sl-icon class="vk" slot="prefix" src="/dist/assets/icons/vk.svg" style="font-size: 32px"></sl-icon>
                VK&nbsp;ID
            </sl-button>
            <sl-button variant="default" pill @click=${() => this.handleLogin('ok_ru')}>
                <sl-icon class="ok" slot="prefix" src="/dist/assets/icons/ok.svg" style="font-size: 28px"></sl-icon>
            </sl-button>
            <sl-button variant="default" pill @click=${() => this.handleLogin('mail_ru')}>
                <sl-icon class="mail" slot="prefix" src="/dist/assets/icons/mail.svg" style="font-size: 22px"></sl-icon>
            </sl-button>
        </sl-button-group>
        `;
    }
}
