import {setIsAuth, setIsAdmin, setUserEmail} from '../states/app-state';
import {fetchGet} from './utils';
import '../components/authorization/app-login';

export async function isAuthorized(): Promise<object> {
    try {
        const result = await fetchGet('/action/user/check-auth');

        if (result.status === 'success') {
            const isAuth = result.data.userId > 0;
            const isAdmin = result.data.userAdmin;
            const userEmail = result.data.userEmail;

            setIsAuth(isAuth);
            setIsAdmin(isAdmin);
            setUserEmail(userEmail);
        }

        return result.data;
    } catch (error) {
        setIsAuth(false);
        setIsAdmin(false);
        setUserEmail('');

        return {
            'userId': 0,
            'isAdmin': false
        };
    }
}

export function openAuth()
{
    const login = document.createElement('app-login') as HTMLElement;
    login.addEventListener('login-dialog-close', () => {
        document.body.removeChild(login);
    });
    document.body.appendChild(login);
}